import { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { useAuth, useAxiosPrivate, useLogout } from "utilities";
import Config from "config";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { SchoolsApi } from "api";
import { useMediaQuery } from "react-responsive";
import { MAX_MOBILE_WIDTH } from "helper/MaxMobileWidthHelper";
import { FaSignInAlt } from "react-icons/fa";
import "styles/animate.css";

export const Header = ({ title, showHeaderMenu = true }) => {
  const { auth } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const isAuthenticated = Boolean(auth?.status !== "unauthenticated");
  const namaPengguna = isAuthenticated ? auth?.decodeToken?.name : "" ?? "";
  const permissions = isAuthenticated ? auth?.permissions : [] ?? [];
  const isMobile = useMediaQuery({ query: MAX_MOBILE_WIDTH });

  const navigation = Config.MENU;
  const profileDropdown = Config.PROFILE_DROPDOWN;

  const getTahunAjaranAktif = useQuery(
    ["header-atribut-tahun-ajaran-aktif"],
    () => SchoolsApi.getTahunAjaran(axiosPrivate, { isActive: true }),
    {
      enabled: Boolean(
        auth?.status !== "unauthenticated" &&
          (permissions.includes("ADMIN") ||
            permissions.includes("GURU") ||
            permissions.includes("WALI_KELAS") ||
            permissions.includes("GURU_BK"))
      ),
    }
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    let pathname = window.location.pathname;

    navigation.length > 0 &&
      navigation.map((item) =>
        item.type === "menu"
          ? pathname === item.link &&
            localStorage.setItem("siswaku-admin-link", item.link)
          : item.menu.map(
              (subItem) =>
                pathname === item.link &&
                localStorage.setItem("siswaku-admin-link", subItem.link)
            )
      );
  }, [navigation]);

  return (
    <div>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open, close }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12 hover:cursor-pointer"
                      src={Config.LOGO}
                      alt="Workflow"
                      onClick={() => navigate("/")}
                    />
                  </div>
                  <div className="hidden sm:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {isAuthenticated ? (
                        showHeaderMenu ? (
                          navigation.length > 0 &&
                          navigation.map((item) => {
                            const menuPath = `/${window.location.pathname.split("/")[1]}`
                            return (
                              <>
                                {item.type === "menu" &&
                                  item.permissions.some((val) =>
                                    permissions.includes(val)
                                  ) && (
                                    <NavLink
                                      key={item.name}
                                      to={item.link}
                                      onClick={() =>
                                        localStorage.setItem(
                                          "siswaku-admin-link",
                                          item.link
                                        )
                                      }
                                      className={classNames(
                                        window.location.pathname === item.link
                                          ? "bg-gray-900 text-white"
                                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                        "px-3 py-2 rounded-md text-sm font-medium"
                                      )}
                                    >
                                      {item.name}
                                    </NavLink>
                                  )}
                                {item.type === "dropdown" &&
                                  item.permissions.some((val) =>
                                    permissions.includes(val)
                                  ) && (
                                    <Menu as="div" className="ml-3 relative">
                                      <div>
                                        <Menu.Button
                                          className={classNames(
                                            menuPath === item.link
                                              ? "bg-gray-900 text-white"
                                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                            "px-3 py-2 rounded-md text-sm font-medium"
                                          )}
                                        >
                                          {item.name}
                                        </Menu.Button>
                                      </div>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="z-[1] origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          {item.menu
                                            .filter((subItem) =>
                                              subItem.permissions.some((val) =>
                                                permissions.includes(val)
                                              )
                                            )
                                            .map((subItem, index) => (
                                              <>
                                                <Menu.Item key={subItem.name}>
                                                  <NavLink
                                                    className={classNames(
                                                      window.location
                                                        .pathname ===
                                                        subItem.link
                                                        ? "bg-gray-900 text-white"
                                                        : "text-gray-700 hover:bg-gray-700 hover:text-white",
                                                      "block px-4 py-2 text-sm"
                                                    )}
                                                    to={subItem.link}
                                                    onClick={() =>
                                                      localStorage.setItem(
                                                        "siswaku-admin-link",
                                                        subItem.link
                                                      )
                                                    }
                                                  >
                                                    {subItem.name}
                                                  </NavLink>
                                                </Menu.Item>
                                                {index !==
                                                  item.menu.filter((subItem) =>
                                                    subItem.permissions.some(
                                                      (val) =>
                                                        permissions.includes(
                                                          val
                                                        )
                                                    )
                                                  ).length -
                                                    1 && <hr />}
                                              </>
                                            ))}
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  )}
                              </>
                            );
                          })
                        ) : (
                          <label className="text-white font-semibold">
                            SILAHKAN SCAN QR CODE DIBAWAH INI UNTUK PRESENSI
                          </label>
                        )
                      ) : (
                        <label className="text-white">
                          SISWAKU - SMP Pangudi Luhur 1 Yogyakarta
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {isAuthenticated ? (
                        <>
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm">
                              <span className="text-white">{namaPengguna}</span>
                              <ChevronDownIcon
                                className="h-6 w-6 pl-2 text-white"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              style={{ zIndex: 1 }}
                            >
                              {profileDropdown.map((val, index) => {
                                return (
                                  <Menu.Item key={index}>
                                    <button
                                      className={classNames(
                                        window.location.pathname === val?.link
                                          ? "bg-gray-900 text-white"
                                          : "text-gray-700 hover:bg-gray-700 hover:text-white",
                                        "block px-4 py-2 text-sm w-full text-left"
                                      )}
                                      onClick={() =>
                                        val?.link
                                          ? navigate(val.link)
                                          : logout()
                                      }
                                    >
                                      {val.name}
                                    </button>
                                  </Menu.Item>
                                );
                              })}
                            </Menu.Items>
                          </Transition>
                        </>
                      ) : (
                        <NavLink
                          key="unauthenticated"
                          to="/login"
                          onClick={() =>
                            localStorage.setItem("siswaku-link", "/login")
                          }
                          className={classNames(
                            window.location.pathname === "/login"
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-bold flex flex-row items-center"
                          )}
                        >
                          <FaSignInAlt className="mr-2 fa-arrow-right" />
                          LOGIN
                        </NavLink>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {showHeaderMenu ? (
                  navigation.length > 0 &&
                  navigation.map((item) => {
                    return (
                      <>
                        {item.type === "menu" &&
                          item.permissions.some((val) =>
                            permissions.includes(val)
                          ) && (
                            <NavLink
                              key={item.name}
                              as="a"
                              to={item.link}
                              onClick={() => {
                                close();
                                localStorage.setItem(
                                  "siswaku-admin-link",
                                  item.link
                                );
                              }}
                              className={classNames(
                                window.location.pathname === item.link
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "block px-3 py-2 rounded-md text-base font-medium"
                              )}
                            >
                              {item.name}
                            </NavLink>
                          )}
                        {item.type === "dropdown" &&
                          item.permissions.some((val) =>
                            permissions.includes(val)
                          ) && (
                            <>
                              <div className="block px-3 py-2 rounded-md text-base font-medium">
                                <div
                                  className={classNames(
                                    window.location.pathname.includes(item.link)
                                      ? "text-white"
                                      : "text-gray-300 hover:text-white",
                                    "text-base font-medium leading-none"
                                  )}
                                >
                                  {item.name}
                                </div>
                              </div>
                              {item.menu
                                .filter((subItem) =>
                                  subItem.permissions.some((val) =>
                                    permissions.includes(val)
                                  )
                                )
                                .map((subItem) => (
                                  <NavLink
                                    key={subItem.name}
                                    as="a"
                                    to={subItem.link}
                                    onClick={() => {
                                      close();
                                      localStorage.setItem(
                                        "siswaku-admin-link",
                                        subItem.link
                                      );
                                    }}
                                    className={classNames(
                                      window.location.pathname === subItem.link
                                        ? "bg-gray-900 text-white"
                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                      "block px-3 py-2 rounded-md text-base font-normal ml-5"
                                    )}
                                  >
                                    {subItem.name}
                                  </NavLink>
                                ))}
                            </>
                          )}
                      </>
                    );
                  })
                ) : (
                  <label className="text-white font-semibold">
                    SILAHKAN SCAN QR CODE DIBAWAH INI UNTUK PRESENSI
                  </label>
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="text-base font-medium leading-none text-white">
                    {isAuthenticated
                      ? namaPengguna
                      : "Silahkan login terlebih dahulu"}
                  </div>
                </div>
                <div className="mt-3 ml-3 px-2 space-y-1">
                  {isAuthenticated ? (
                    profileDropdown.map((val, index) => {
                      return (
                        <Disclosure.Button
                          as="a"
                          key={index}
                          className={classNames(
                            window.location.pathname === val?.link
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "block px-3 py-2 rounded-md text-base font-normal ml-5"
                          )}
                          onClick={() =>
                            val?.link ? navigate(val.link) : logout()
                          }
                        >
                          {val.name}
                        </Disclosure.Button>
                      );
                    })
                  ) : (
                    <Disclosure.Button
                      as="a"
                      key="unauthenticated-mobile"
                      className={classNames(
                        window.location.pathname === "/login"
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-normal ml-5"
                      )}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Disclosure.Button>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <header className="bg-white shadow">
        <div className="mx-auto py-4 px-4 sm:px-6 lg:px-8 min-h-[60px]">
          <div className="grid grid-cols-2">
            <div className="">
              <h1 className="text-xl tracking-tight font-bold text-gray-900">
                {title ?? "Beranda"}
              </h1>
            </div>
            {auth?.status !== "unauthenticated" ? (
              getTahunAjaranAktif?.data?.[0]?.periode === undefined ? (
                <></>
              ) : (
                <div className="justify-self-end">
                  <span className="text-sm sm:text-lg text-white font-semibold bg-rose-500 px-2 py-1 rounded-md">
                    {getTahunAjaranAktif.isFetching ||
                    getTahunAjaranAktif.isError
                      ? getTahunAjaranAktif?.isError
                        ? getTahunAjaranAktif?.error?.response?.data
                            ?.errorMessage?.[0]
                        : "Memuat tahun ajaran..."
                      : `${isMobile ? "TA " : "Tahun Ajaran "}${
                          getTahunAjaranAktif?.data?.[0]?.periode
                        }`}
                  </span>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};
